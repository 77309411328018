import React, { useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import bannerPic from "../assets/images/kr-banner-pic-01.png";
import "./HomePageBanner.css";

const HomePageBanner = () => {
  useEffect(() => {
    const bannerImage = document.querySelector(".banner-image");
    bannerImage.classList.add("fade-in");
  }, []);

  return (
    <div className="banner">
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="banner-content">
            <h1>Your journey to success begins here</h1>
            <p>
              We are committed to helping you achieve your business goals
              through innovative and tailored solutions.
            </p>
            <Button variant="primary" size="lg">
              Get Started
            </Button>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <img
              src={bannerPic} // Replace with your image URL
              alt="Software Development Banner"
              className="img-fluid banner-image"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePageBanner;
