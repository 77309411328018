// src/pages/GraphicDesign.js
import React from "react";
import Page from "../components/common/Page";
import { Container } from "react-bootstrap";

const GraphicDesign = () => {
  return (
    <Page title="Graphic Design">
      <Container>
        <h1>Graphic Design</h1>
        <p>
          Our graphic design services include creating visually appealing
          materials such as brochures, banners, and social media graphics. We
          focus on delivering designs that communicate your message effectively
          and attract your target audience.
        </p>
      </Container>
    </Page>
  );
};

export default GraphicDesign;
