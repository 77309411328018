import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import "./Service.css";
import IntroSection from "../components/common/IntroSection";
import HelmetWrapper from "../components/common/HelmetWrapper";

// Importing Bootstrap icons from react-icons
import { BsBrush, BsCodeSlash, BsPhone, BsPencilSquare, BsPalette, BsWrench } from "react-icons/bs";

const Service = () => {
  return (
    <div className="services-page">
      <HelmetWrapper title="Our Services" />
      <CommonBanner title="Services" />
      <IntroSection
        title="Our Services"
        description="At Krishabh, we offer a range of services designed to help you achieve your business goals. Our team of experts is committed to providing innovative and tailored solutions to meet your unique needs."
      />
      <section className="service-section">
        <Container>
          <Row>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsBrush size={50} className="service-icon" />
                </div>
                <h2>Website Design</h2>
                <p>Visually stunning and user-friendly websites for your brand.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsCodeSlash size={50} className="service-icon" />
                </div>
                <h2>Website Development</h2>
                <p>Building robust and scalable websites with tailored features.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsPhone size={50} className="service-icon" />
                </div>
                <h2>Mobile App Development</h2>
                <p>High-performance apps for engaging mobile experiences.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsPencilSquare size={50} className="service-icon" />
                </div>
                <h2>UI/UX Design</h2>
                <p>Creating intuitive designs that resonate with your audience.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsPalette size={50} className="service-icon" />
                </div>
                <h2>Graphic Design</h2>
                <p>Eye-catching designs that effectively convey your message.</p>
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <div className="service-item">
                <div className="icon-modern">
                  <BsWrench size={50} className="service-icon" />
                </div>
                <h2>Website Maintenance</h2>
                <p>Reliable updates and support to keep your website secure.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Service;
