import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import validation icons
import "./Contact.css"; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  const [submitted, setSubmitted] = useState(false);

  const validateEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validation
    if (name === "email") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: !validateEmail(value),
      }));
    } else if (name !== "message") {
      // Only validate fields that are mandatory
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value.trim() === "",
      }));
    }
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields on submit
    const newErrors = {
      name: formData.name.trim() === "",
      email: !validateEmail(formData.email),
      subject: formData.subject.trim() === "",
      message: false, // No validation for message field
    };

    setErrors(newErrors);
    setSubmitted(true);

    // Check if there are no errors
    if (!newErrors.name && !newErrors.email && !newErrors.subject) {
      // Redirect to success page
      navigate("/success");
    }
  };

  // Extract validation icons
  const getNameIcon = () => {
    if (errors.name && submitted) {
      return <FaTimesCircle className="invalid-icon" />;
    } else if (formData.name && !errors.name) {
      return <FaCheckCircle className="valid-icon" />;
    }
    return null;
  };

  const getEmailIcon = () => {
    if (errors.email && submitted) {
      return <FaTimesCircle className="invalid-icon" />;
    } else if (formData.email && !errors.email) {
      return <FaCheckCircle className="valid-icon" />;
    }
    return null;
  };

  const getSubjectIcon = () => {
    if (errors.subject && submitted) {
      return <FaTimesCircle className="invalid-icon" />;
    } else if (formData.subject && !errors.subject) {
      return <FaCheckCircle className="valid-icon" />;
    }
    return null;
  };

  const getMessageIcon = () => {
    // No validation icons for the message field
    return null;
  };

  return (
    <div className="contact-page">
      <CommonBanner title="Contact Us" />
      <section className="contact-form-section">
        <Container>
          <Row>
            <Col md={6} className="contact-info">
              <h2>Get in Touch</h2>
              <p>
                We're here to help! Whether you have questions, need support, or
                want to provide feedback, please reach out to us using the
                contact form below.
              </p>
              <h3>Our Address</h3>
              <p>
                28/A/406, Niwara Apartments,
                <br />
                Farm Road Chandivali, Andheri East, Mumbai-400072
                <br />
                India
              </p>
              <h3>Contact Information</h3>
              <p>
                Phone: +19-7387195791
                <br />
                Email: contact@krishabh.com
              </p>
            </Col>
            <Col md={6}>
              <h2>Contact Form</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter your name"
                      isInvalid={errors.name && submitted}
                    />
                    {getNameIcon()}
                  </div>
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your email"
                      isInvalid={errors.email && submitted}
                    />
                    {getEmailIcon()}
                  </div>
                </Form.Group>

                <Form.Group controlId="formSubject">
                  <Form.Label>Subject</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Enter the subject"
                      isInvalid={errors.subject && submitted}
                    />
                    {getSubjectIcon()}
                  </div>
                </Form.Group>

                <Form.Group controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <div className="input-wrapper">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Your message (optional)"
                    />
                    {getMessageIcon()}
                  </div>
                </Form.Group>

                <Button variant="primary" type="submit" className="submit-btn">
                  Send Message
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contact;
