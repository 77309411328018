// src/pages/Home.js
import React from "react";
import HomePageBanner from "../components/HomePageBanner";
import OurServices from "../components/OurServices";
import WelcomeSection from "../components/WelcomeSection";
import MissionVisionValues from "../components/MissionVisionValues";
import PortfolioSection from "../components/PortfolioSection";
import Testimonials from "../components/Testimonials";
import HelmetWrapper from "../components/common/HelmetWrapper";

const Home = () => {
  return (
    <div>
      <HelmetWrapper title="Home" />
      <HomePageBanner />
      <WelcomeSection />
      <MissionVisionValues />
      <OurServices />
      <PortfolioSection />
      <Testimonials />
    </div>
  );
};

export default Home;
