// src/components/PortfolioSection.js
import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
// Import images
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.png";
// Add other project imports...

const portfolioItems = [
  {
    title: "Kavya Books Store",
    image: project1,
    url: "https://kavyabooksstore.com",
    techStack: "WordPress, Bootstrap, WooCommerce", // Add tech stack information
  },

  {
    title: "Urmila's Beauty Parlour & Academy",
    image: project2,   
    comingSoon: true, // Add a flag for coming soon
    techStack: "React JS, Next JS, Bootstrap, PHP, MYSQL, Firebase ", // Add tech stack information
  },
  // Add more projects with tech stack info...
];

const PortfolioSection = () => {
  return (
    <section className="portfolio-section">
      <Container>
        <h2 className="section-title">Our Works</h2>
        <Row>
          {portfolioItems.map((item, index) => (
            <Col md={4} key={index}>
              <Card className="portfolio-card">
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                  <Card.Text className="tech-stack">
                    <strong>Technologies Used:</strong> {item.techStack}{" "}
                    {/* Display tech stack */}
                  </Card.Text>
                  {item.comingSoon ? (
                    <span className="coming-soon-text">Coming Soon</span>
                  ) : (
                    <Button
                      variant="primary"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Project
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default PortfolioSection;
