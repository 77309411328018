import React from "react";
import { Container } from "react-bootstrap";
import "./CommonBanner.css"; // Import the CSS file for header styles

const CommonBanner = ({ title }) => {
  return (
    <header className="page-banner">
      <Container>
        <h1>{title}</h1>
      </Container>
    </header>
  );
};

export default CommonBanner;
