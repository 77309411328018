// src/pages/WebsiteMaintenance.js
import React from "react";
import Page from "../components/common/Page";
import { Container } from "react-bootstrap";

const WebsiteMaintenance = () => {
  return (
    <Page title="Website Maintenance">
      <Container>
        <h1>Website Maintenance</h1>
        <p>
          Keep your website running smoothly with our maintenance services. We
          provide regular updates, security patches, and technical support to
          ensure that your website remains secure and up-to-date.
        </p>
      </Container>
    </Page>
  );
};

export default WebsiteMaintenance;
