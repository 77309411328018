// src/pages/MobileAppDevelopment.js
import React from "react";
import Page from "../components/common/Page";
import { Container } from "react-bootstrap";

const MobileAppDevelopment = () => {
  return (
    <Page title="Mobile App Development">
      <Container>
        <h1>Mobile App Development</h1>
        <p>
          Expand your reach with a mobile app designed to provide an exceptional
          user experience on smartphones and tablets. Our development team
          builds high-performance apps that are both functional and engaging.
        </p>
      </Container>
    </Page>
  );
};

export default MobileAppDevelopment;
