// src/pages/WebsiteDevelopment.js
import React from "react";
import Page from "../components/common/Page";
import { Container } from "react-bootstrap";

const WebsiteDevelopment = () => {
  return (
    <Page title="Website Development">
      <Container>
        <h1>Website Development</h1>
        <p>
          Our development team builds robust and scalable websites using the
          latest technologies. We ensure that your website not only looks great
          but also performs optimally, with features and functionality tailored
          to your needs.
        </p>
      </Container>
    </Page>
  );
};

export default WebsiteDevelopment;
