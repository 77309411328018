import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SuccessPage.css"; // Create and import a CSS file for styling

const SuccessPage = () => {
  return (
    <div className="success-page">
      <header className="success-banner">
        <Container>
          <h1>Success!</h1>
        </Container>
      </header>

      <section className="success-content">
        <Container>
          <Row>
            <Col className="text-center">
              <h2>Form Submitted Successfully!</h2>
              <p>
                Thank you for reaching out to us. We have received your message
                and will get back to you as soon as possible. Your feedback is
                valuable to us, and we appreciate you taking the time to contact
                us.
              </p>
              <p>
                In the meantime, feel free to explore our website and learn more
                about our services and offerings. If you have any further
                questions, don't hesitate to get in touch again.
              </p>
              <Link to="/" className="btn btn-primary">
                Go to Homepage
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SuccessPage;
