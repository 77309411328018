import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WebsiteDesign from "./pages/WebsiteDesign";
import WebsiteDevelopment from "./pages/WebsiteDevelopment";
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import WebsiteMaintenance from "./pages/WebsiteMaintenance";
import GraphicDesign from "./pages/GraphicDesign";
import SuccessPage from "./pages/SuccessPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.scss"; // Import the custom CSS

const App = () => (
  <Router>
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <main className="flex-grow-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/website-design" element={<WebsiteDesign />} />
          <Route path="/website-development" element={<WebsiteDevelopment />} />
          <Route
            path="/mobile-app-development"
            element={<MobileAppDevelopment />}
          />
          <Route path="/website-maintenance" element={<WebsiteMaintenance />} />
          <Route path="/graphic-design" element={<GraphicDesign />} />
        </Routes>
      </main>
      <Footer />
    </div>
  </Router>
);

export default App;
