import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import welcomePic from "../assets/images/kr-welcome-pic.png";

const WelcomeSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/about'); // Redirect to the About Us page
  };

  return (
    <section className="welcome-section">
      <Container>
        <Row>
          <Col md={6} className="image-col">
            <img src={welcomePic} alt="Welcome Pic" />
          </Col>
          <Col md={6} className="text-col">
            <h2>Welcome to Krishabh</h2>
            <p>
              We bring you the finest services and solutions tailored to meet
              your needs. Our team of experts is dedicated to ensuring your
              success through innovative and customized approaches.
            </p>
            <Button variant="primary" size="lg" onClick={handleClick}>
              Read More
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WelcomeSection;
