import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import serviceBgPic from "../assets/images/kr-services-bg-pic.jpg"; // Import the image
import ServiceSection from "./ServiceSection";
import "bootstrap-icons/font/bootstrap-icons.css";

const services = [
  {
    icon: "bi-palette",
    title: "Website Design",
    description:
      "Create beautiful and responsive websites tailored to your needs.",
  },
  {
    icon: "bi-code-slash",
    title: "Website Development",
    description:
      "Build robust applications using modern technologies and frameworks.",
  },
  {
    icon: "bi-phone",
    title: "Mobile App Development",
    description:
      "Develop high-performance mobile applications for iOS and Android.",
  },
  {
    icon: "bi-easel",
    title: "UI/UX Design",
    description:
      "Craft intuitive and engaging user interfaces and experiences.",
  },
  {
    icon: "bi-brush",
    title: "Graphic Design",
    description:
      "Design visually appealing graphics and promotional materials.",
  },
  {
    icon: "bi-wrench",
    title: "Website Maintenance ",
    description:
      "Provide ongoing support and maintenance for your applications.",
  },
];

const OurServices = () => {
  const sectionStyle = {
    background: `linear-gradient(to bottom, rgba(50, 64, 148, 0.7), rgba(51, 174, 255, 0.7)), url(${serviceBgPic})`,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <section className="our-services" style={sectionStyle}>
      <Container>
        <h2 className="section-title">Our Services</h2>
        <Row className="service-cards">
          {services.map((service, index) => (
            <Col md={4} className="mb-4" key={index}>
              <ServiceSection
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OurServices;
