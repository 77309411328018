// src/pages/WebsiteDesign.js
import React from "react";
import Page from "../components/common/Page";
import { Container } from "react-bootstrap";

const WebsiteDesign = () => {
  return (
    <Page title="Website Design">
      <Container>
        <h1>Website Design</h1>
        <p>
          We create visually stunning and user-friendly websites that captivate
          your audience. Our design process focuses on crafting intuitive user
          experiences and responsive designs that reflect your brand's identity.
        </p>
      </Container>
    </Page>
  );
};

export default WebsiteDesign;
