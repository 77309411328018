// src/components/ServiceSection.js
import React from "react";
import PropTypes from "prop-types";

const ServiceSection = ({ icon, title, description }) => {
  return (
    <div className="service-section text-center p-4 mb-4 shadow-sm bg-white rounded">
      <div className="icon mb-3">
        <i className={`bi ${icon} custom-icon`}></i>
      </div>
      <h3 className="service-title mb-2">{title}</h3>
      <p className="service-description">{description}</p>
    </div>
  );
};

ServiceSection.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ServiceSection;
