import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import project1 from "../assets/images/kr-project-01-pic.jpg";
import project2 from "../assets/images/kr-project-02-pic.png";
import "./Portfolio.css"; // Import the CSS file for styling
import HelmetWrapper from "../components/common/HelmetWrapper";

const portfolioItems = [
  {
    image: project1,
    title: "Kavya Books Store",
    url: "https://kavyabooksstore.com",
    technologies: ["WordPress", "Bootstrap", "WooCommerce"],
  },
  {
    image: project2, // Replace with the correct image path for this project
    title: "Urmila's Beauty Parlour & Academy",
    technologies: ["React JS", "Next JS", "PHP", "MYSQL", "Firebase"], // Example tech stack
    comingSoon: true, // Set comingSoon flag to true
  },
];

const Portfolio = () => {
  return (
    <div className="portfolio-page">
      <HelmetWrapper title="Portfolio" />
      <CommonBanner title="Portfolio" />
      <section className="intro-section">
        <Container className="intro-content">
          <h1>Our Portfolio</h1>
          <p>
            Explore our portfolio to see the diverse range of projects we have
            worked on. Our team has delivered outstanding results across various
            industries, showcasing our expertise and creativity.
          </p>
        </Container>
      </section>
      <section className="portfolio-section">
        <Container>
          <Row>
            {portfolioItems.map((item, index) => (
              <Col md={4} className="mb-4" key={index}>
                <div className="portfolio-item">
                  <img
                    src={item.image}
                    alt={`Portfolio Item ${index + 1}`}
                    className="portfolio-img"
                  />
                  <div className="portfolio-info">
                    <h3 className="portfolio-title">{item.title}</h3>
                    <p className="portfolio-technologies">
                      Technologies: {item.technologies.join(", ")}
                    </p>
                    {item.comingSoon ? (
                      <span className="coming-soon-text">Coming Soon</span>
                    ) : (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="portfolio-button"
                      >
                        View Project
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Portfolio;
