import React from "react";
import { Container } from "react-bootstrap";
import "./IntroSection.css"; // Import the CSS file for intro section styles

const IntroSection = ({ title, description }) => {
  return (
    <section className="intro-section">
      <Container className="intro-content">
        <h1>{title}</h1>
        <p>{description}</p>
      </Container>
    </section>
  );
};

export default IntroSection;
