// src/components/Page.js
import React from "react";

import "./Page.css"; // Import CSS for styling

const Page = ({ title, children }) => {
  React.useEffect(() => {
    document.title = title; // Set the page title
  }, [title]);

  return (
    <div className="page">
      <main className="page-content">{children}</main>
    </div>
  );
};

export default Page;
